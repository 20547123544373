import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import AuthContext from '../../AuthContext'
import '../../styles/Documents.css'
import SimpleDocumentView from '../../SimpleDocumentViewer'
import { BASE_URL } from '../../config'
import Pagination from '../Pagination'
import { BeatLoader } from 'react-spinners'
import Report from '../Reports'
import Header from '../Header'
import DocumentEditor from '../utils/DocumentEditor'
import ErrorBoundary from '../utils/ErrorBoundary'
import Clauses from '../Clauses'
import { renderAsync } from 'docx-preview'
import { toast } from 'react-toastify'
import { Document, Page } from 'react-pdf'
import useClickOutside from '../hooks/useClickOutside'

// *** Import React-Select ***
import Select from "react-select"

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#000',
  justifyContent: 'center',
}

const Documents = () => {
  const [files, setFiles] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const [selectedFileId, setSelectedFileId] = useState(null)
  const [selectFileToEdit, setSelectFileToEdit] = useState(null)
  const { userToken, userRole } = useContext(AuthContext)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, settotalPages] = useState(0)
  const [activeTab, setActiveTab] = useState('documents')

  const [searchKeyword, setSearchKeyword] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('')
  const [filterLabel, setFilterLabel] = useState('Show')
  const [categoryColors, setCategoryColors] = useState({})

  // Examine Modal State
  const [showExamineModal, setShowExamineModal] = useState(false)
  const [examineDocumentUrl, setExamineDocumentUrl] = useState('')
  const [examineNumPages, setExamineNumPages] = useState(null)
  const [examineFile, setExamineFile] = useState(null)
  const examineContainerRef = useRef(null)

  const [filepath, setfilepath] = useState('')
  const [showModal2, setShowModal2] = useState(false)
  const [is_password_required, setis_password_required] = useState('')
  const [link_id, setlink_id] = useState('')
  const [file_id, setfile_id] = useState('')
  const [url, seturl] = useState('')
  const [isURL, setisURL] = useState(false)
  const [password, setpassword] = useState('')
  const [aiExplanations, setAiExplanations] = useState([])
  const [useClaude, setUseClaude] = useState(false)
  const [selectedText, setSelectedText] = useState('')
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false)
  const [editFile, setEditFile] = useState(null)
  const [newCategory, setNewCategory] = useState('')

  const [selectedCategoryDocument, setSelectedCategoryDocument] = useState('')
  const [categoriesForDocument, setCategoriesForDocument] = useState([])
  const [buyerOrSellerForDocument, setBuyerOrSellerForDocument] = useState('')
  const [selectedReferenceDoc, setSelectedReferenceDoc] = useState('')

  // Reference Docs State (No Pagination)
  const [referenceDocs, setReferenceDocs] = useState([])
  const fileInputRef = useRef(null) // For uploading new reference doc

  // NEW: For multi-select with React Select
  const [multiRefOptions, setMultiRefOptions] = useState([])      // array of { value, label } from referenceDocs
  const [multiSelectedRefs, setMultiSelectedRefs] = useState([])  // user selection

  // --------------------------------------------------------
  // NEW STATES FOR REDLINE TAB
  // --------------------------------------------------------
  const [redlineSelectedCategory, setRedlineSelectedCategory] = useState('')
  const [redlineBuyerOrSeller, setRedlineBuyerOrSeller] = useState('')
  const [redlineSelectedReferenceDoc, setRedlineSelectedReferenceDoc] =
    useState('')
  const redlineEditorRef = useRef(null)

  // SEPARATE STATE FOR 2ND SELECT (Select a Redline Document)
  const [redlineDocumentForComparison, setRedlineDocumentForComparison] =
    useState('')

  // Get categories on mount (for the create doc dropdown)
  useEffect(() => {
    const fetchCategoriesForDoc = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user/get-categories`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        setCategoriesForDocument(response.data)
      } catch (error) {
        console.error('Error fetching categories:', error)
      }
    }
    fetchCategoriesForDoc()
  }, [userToken])

  // For category select in create doc
  const handleCategoryChangeForDocument = (event) => {
    const categoryId = event.target.value
    setSelectedCategoryDocument(categoryId)
  }

  // For Redline doc
  const handleCategoryChangeForRedline = (event) => {
    const categoryId = event.target.value
    setRedlineSelectedCategory(categoryId)
  }

  // For "Edit Category" modal
  const updateCategoryRef = useClickOutside(() => {
    if (showEditCategoryModal) setShowEditCategoryModal(false)
  })

  // For "Share" modal
  const handleShowModal2 = () => setShowModal2(true)

  const editorRef = useRef(null)

  const onExamineDocumentLoadSuccess = ({ numPages }) => {
    setExamineNumPages(numPages)
  }

  // Create share link logic (existing code)
  const createLink = async () => {
    if (is_password_required === 'yes' && password) {
      LinkAPI()
    } else if (is_password_required === 'yes' && !password) {
      toast.error('Please enter password')
    } else {
      LinkAPI()
    }
  }

  const LinkAPI = async () => {
    if (isURL) {
      // Edit existing link
      try {
        const response = await axios.post(
          `${BASE_URL}/user/files/link/edit`,
          {
            link_id: link_id,
            is_password_required: is_password_required,
            password: password,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        )

        if (response.data.status === 1) {
          toast.success(response.data.message)
          fetchFiles(currentPage)
        } else {
          toast.error(response.data.message)
        }
      } catch (error) {
        console.error('Error editing link:', error)
      }
    } else {
      // Create new link
      try {
        const response = await axios.post(
          `${BASE_URL}/user/files/link/create`,
          {
            file_id: file_id,
            is_password_required: is_password_required,
            password: password,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        )

        if (response.data.status === 1) {
          seturl(response.data.data.lunk_url)
          fetchFiles(currentPage)
          toast.success(response.data.message)
        } else {
          toast.error(response.data.message)
        }
      } catch (error) {
        console.error('Error creating link:', error)
      }
    }
  }

  // For loading DOCX in the examine modal
  const handleExamineFileUpload = async (docUrl) => {
    try {
      const response = await fetch(docUrl)
      if (!response.ok) throw new Error('Failed to fetch file')

      const arrayBuffer = await response.arrayBuffer()
      if (examineContainerRef.current) {
        await renderAsync(arrayBuffer, examineContainerRef.current)
      }
    } catch (error) {
      console.error('Error loading DOCX file:', error)
    }
  }

  // Switch tabs
  const handleTabChange = (tab) => {
    setActiveTab(tab)
  }

  // Page change for main documents table
  const handlePageChange = (page) => {
    setCurrentPage(page)
    fetchFiles(page)
  }

  // Fetch category colors
  const fetchCategoryColors = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/getColors`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      if (response.data && response.data.category_colors) {
        setCategoryColors(response.data.category_colors)
      }
    } catch (error) {
      console.error('Error fetching category colors:', error)
    }
  }

  // ---------------------------------
  // FETCH REFERENCE DOCS (ALL AT ONCE)
  // ---------------------------------
  const fetchReferenceDocs = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/allReferenceFiles/list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      })
      if (response.data.status === 1) {
        setReferenceDocs(response.data.data || [])

        // Build "options" for react-select
        const opts = response.data.data.map((doc) => ({
          value: doc._id,
          label: doc.filename,
        }))
        setMultiRefOptions(opts)
      } else {
        console.error('Failed to fetch reference docs:', response.data.message)
      }
    } catch (error) {
      console.error('Error fetching reference docs:', error)
    }
  }

  // Trigger reference docs fetch once when we go to "create-doc" or "redline" tab
  useEffect(() => {
    if (activeTab === 'create-doc' || activeTab === 'redline') {
      fetchReferenceDocs()
    }
  }, [activeTab])

  // Fetch main files & colors on mount
  useEffect(() => {
    fetchFiles()
    fetchCategoryColors()
  }, [userToken, userRole])

  // Additional effect to handle search user
  useEffect(() => {
    const isSearchUser = localStorage.getItem('isSearchUser')
    if (isSearchUser) {
      setSelectedFileId(isSearchUser)
      localStorage.removeItem('isSearchUser')
    }
    fetchFiles()
    fetchCategories()
  }, [userToken, userRole])

  // Fetch all files for the Documents tab
  const fetchFiles = async (current) => {
    try {
      const pageToFetch = current || currentPage
      const response = await axios.post(
        `${BASE_URL}/${userRole}/allFiles/list`,
        {
          page: pageToFetch,
          size: '10',
          keyword: searchKeyword,
          start_date: startDate,
          end_date: endDate,
          category_id: selectedCategory,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data.status === 1) {
        settotalPages(response.data.totalPages)
        setFiles(response.data.data)
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      console.error('Error fetching files:', error)
      setError('Failed to fetch files')
    } finally {
      setLoading(false)
    }
  }

  // Fetch file details for Examine
  const fetchFileDetails = async (fileId) => {
    try {
      const response = await axios.get(`${BASE_URL}/global/file-details/${fileId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      })
      if (response.status === 200) {
        handleExamine(response.data.data._doc)
      }
    } catch (error) {
      console.error('Error fetching file details:', error)
      return {}
    }
  }

  // View the file in simple doc viewer
  const handleViewFile = (fileId) => {
    setSelectedFileId(fileId)
  }

  // Edit the file in Document Editor
  const handleEdit = (fileId) => {
    setSelectFileToEdit(fileId)
  }

  // Return from SimpleDocumentView or DocumentEditor
  const handleBack = () => {
    setSelectedFileId(null)
    setSelectFileToEdit(null)
  }

  // If editing the doc
  if (selectFileToEdit) {
    return (
      <ErrorBoundary>
        <DocumentEditor fileId={selectFileToEdit} handleBack={handleBack} />
      </ErrorBoundary>
    )
  }

  // If viewing the doc
  if (selectedFileId) {
    return <SimpleDocumentView fileId={selectedFileId} handleBack={handleBack} />
  }

  // For Examine modal
  const handleExamine = (file) => {
    if (!file || !file.filename_encoded) {
      console.error('Invalid file object or missing filename_encoded.')
      toast.error('The selected file is invalid or missing information.')
      return
    }

    setExamineFile(file)
    setShowExamineModal(true)
    setExamineNumPages(null)

    const docUrl = `${filepath}/${file.filename_encoded}`
    setExamineDocumentUrl(docUrl)

    if (file.filename_encoded.endsWith('.docx')) {
      handleExamineFileUpload(docUrl)
    }
  }

  // If we encountered an error
  if (error) {
    return <p className="error">{error}</p>
  }

  // Handle search on pressing Enter
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true)
      fetchFiles(1)
    }
  }

  // Filter the Documents list
  const handleFilterFiles = () => {
    setFilterLabel((prev) => (prev === 'Hide' ? 'Show' : 'Hide'))
    if ((startDate && endDate) || selectedCategory) {
      setLoading(true)
      fetchFiles(currentPage)
    }
  }

  // Additional fetch for categories in the documents tab
  const fetchCategories = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/category/list`,
        {
          page: '0',
          size: '10',
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data && response.data.status === 1) {
        setCategories(response.data.categories)
      } else {
        setError(response.data?.data?.message || 'Failed to fetch categories')
      }
    } catch (error) {
      setError('Failed to fetch categories')
    }
  }

  // "Fix It" feature (existing)
  const handleFixIt = async (file) => {
    try {
      toast.info('Your document is being processed. This may take a few minutes.')

      const response = await axios.post(
        `${BASE_URL}/user/fix-it`,
        { file_id: file._id },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
        }
      )

      if (response.status === 200) {
        toast.success('Processing complete! Your document will now download.')

        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        })
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', `redlined-${file.filename}.docx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        toast.error(response.data.message || 'Failed to process the document.')
      }
    } catch (error) {
      console.error('Error processing the file:', error)
      toast.error('Failed to process the document.')
    }
  }

  // For AI text selection (Examine modal)
  const handleTextSelection = () => {
    let text = ''
    if (window.getSelection) {
      text = window.getSelection().toString()
    }
    setSelectedText(text)
  }

  const handleAnalyzeText = async () => {
    if (!selectedText) return

    const newEntry = {
      selectedText,
      aiResponse: '',
      loading: true,
    }
    setAiExplanations((prev) => [...prev, newEntry])

    try {
      const response = await axios.post(
        `${BASE_URL}/user/analyze-text`,
        {
          text: selectedText,
          provider: useClaude ? 'claude' : 'openai',
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data && response.data.status === 1) {
        setAiExplanations((prev) =>
          prev.map((item, index) =>
            index === prev.length - 1
              ? {
                  ...item,
                  aiResponse: response.data.explanation,
                  loading: false,
                }
              : item
          )
        )
        setSelectedText('')
      } else {
        toast.error('Failed to get AI explanation.')
        setAiExplanations((prev) => prev.slice(0, -1))
      }
    } catch (error) {
      console.error('Error analyzing text:', error)
      toast.error('Error analyzing text.')
      setAiExplanations((prev) => prev.slice(0, -1))
    }
  }

  // Creating a doc with AI for "Generate Document" tab
  const handleCreateDocument = async () => {
    const content = editorRef.current.innerHTML

    if (!selectedCategoryDocument || !buyerOrSellerForDocument) {
      toast.error('Please fill in all required fields before creating the document.')
      return
    }

    try {
      toast.info('Generating your document. Please wait...')

      const response = await axios.post(
        `${BASE_URL}/user/createDocument`,
        {
          categoryId: selectedCategoryDocument,
          buyerOrSeller: buyerOrSellerForDocument,
          referenceDocIds: multiSelectedRefs.map((ref) => ref.value),
          progress: content || '',
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
        }
      )

      if (response.status === 200) {
        toast.success('Document generated successfully! Starting download.')

        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        })
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', `completed-document.docx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        toast.error('Failed to generate the document.')
      }
    } catch (error) {
      console.error('Error creating document:', error)
      toast.error('Error generating the document.')
    }
  }

  // -----------------------------------------------------------------
  // REDLINE TAB (NOW CALLS fixIt ENDPOINT)
  // -----------------------------------------------------------------
  const handleRedlineDocument = async () => {
    // We need at least a doc to fix
    if (!redlineDocumentForComparison) {
      toast.error('Please select a main document to redline.')
      return
    }

    try {
      toast.info('Processing redline...')

      // Make a POST request to fixIt
      // file_id => the doc we want to redline
      // reference_doc_id => optional reference doc
      // categoryId => redlineSelectedCategory
      // buyerOrSeller => redlineBuyerOrSeller
      // The text content in redlineEditorRef is optional for your usage,
      // but currently the fixIt endpoint doesn't need it. We'll omit it.

      const response = await axios.post(
        `${BASE_URL}/user/fix-it`,
        {
          file_id: redlineDocumentForComparison,
          reference_doc_id: redlineSelectedReferenceDoc || undefined,
          categoryId: redlineSelectedCategory || undefined,
          buyerOrSeller: redlineBuyerOrSeller || undefined,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          responseType: 'blob', // We expect a file
        }
      )

      if (response.status === 200) {
        toast.success('Redline complete! Your file will now download.')

        // Download the returned DOCX
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        })
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', 'redlined-document.docx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        toast.error('Failed to generate the redlined document.')
      }
    } catch (error) {
      console.error('Error generating redline:', error)
      toast.error('Error generating the redlined document.')
    }
  }

  // Edit the category of a file
  const handleEditCategory = (file) => {
    setEditFile(file)
    setSelectedCategory(file.qc_id?._id || '')
    setNewCategory(file.qc_id?._id || '')
    setShowEditCategoryModal(true)
  }

  const handleCategoryChange = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/global/file/update-category`,
        { fileId: editFile._id, categoryId: selectedCategory },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )

      if (response.data.status === 1) {
        setShowEditCategoryModal(false)
        alert('File updated and re-uploaded successfully!')
      } else {
        alert('Failed to update the file.')
      }
    } catch (error) {
      console.error('Error updating category:', error)
      alert('An error occurred while updating the category.')
    }
  }

  // Upload a new reference doc
  const handleUploadReferenceFile = async (file) => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const response = await axios.post(
        `${BASE_URL}/global/upload-reference-file`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.data.status === 1) {
        toast.success('Reference file uploaded successfully!')
        // Refetch reference docs
        const refDocId = response.data.data._id
        await fetchReferenceDocs()
        // Optionally set the newly uploaded doc
        setSelectedReferenceDoc(refDocId)
      } else {
        toast.error(response.data.message || 'Failed to upload reference file.')
      }
    } catch (error) {
      console.error('Error uploading reference file:', error)
      toast.error('Error uploading reference file.')
    }
  }

  // Show file chooser
  const handleChooseReferenceFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  // On file choose
  const onFileInputChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      handleUploadReferenceFile(e.target.files[0])
      e.target.value = '' // reset
    }
  }

  // React-Select multi handlers
  const handleMultiSelectChange = (selected) => {
    // set the entire array (value, label)
    setMultiSelectedRefs(selected || [])
  }

  return (
    <>
      <div className="wrapper">
        <div className="content-area px-4 pt-4" style={{ marginLeft: '0px' }}>
          <header className="header-flex">
            <div></div>
            <Header />
          </header>

          <div className="documents-container">
            <div className="doc_nav_tbas_buttons">
              {/* Navigation for tabs */}
              <button
                onClick={() => handleTabChange('documents')}
                className={activeTab === 'documents' ? 'active' : ''}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-file"
                >
                  <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                  <polyline points="13 2 13 9 20 9"></polyline>
                </svg>
                &nbsp;&nbsp; Documents
              </button>
              <button
                onClick={() => handleTabChange('reports')}
                className={activeTab === 'reports' ? 'active' : ''}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-pie-chart"
                >
                  <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                  <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                </svg>
                &nbsp;&nbsp; Reports
              </button>
              <button
                className={activeTab === 'Clauses' ? 'active' : ''}
                onClick={() => handleTabChange('Clauses')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-file-text"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" y1="13" x2="8" y2="13"></line>
                  <line x1="16" y1="17" x2="8" y2="17"></line>
                  <polyline points="10 9 9 9 8 9"></polyline>
                </svg>
                &nbsp; Clauses
              </button>
              <button
                className={activeTab === 'create-doc' ? 'active' : ''}
                onClick={() => handleTabChange('create-doc')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-edit"
                >
                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3l-9 9-4 1 1-4 9-9z"></path>
                </svg>
                &nbsp; Generate Document
              </button>

              {/* <button
                className={activeTab === 'redline' ? 'active' : ''}
                onClick={() => handleTabChange('redline')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-edit-3"
                >
                  <path d="M12 20h9"></path>
                  <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4 9-9z"></path>
                </svg>
                &nbsp; Redline
              </button>
           */}

            </div> 

            {/* Conditional rendering based on active tab */}
            {activeTab === 'documents' && (
              <div className="px-1">
                <div className="cards mt-2">
                  {/* Search input */}
                  <div className="col-3 mx-0 p-3" style={{ float: 'left' }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here"
                      onKeyUp={(e) => {
                        setSearchKeyword(e.target.value)
                        handleKeyPress(e)
                      }}
                    />
                  </div>
                  <div className="col-3 mx-0 p-3" style={{ float: 'left' }}>
                    <button
                      className="accordion-button readline-btn-down text-center"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      style={{
                        color: '#fff',
                        width: '100px',
                        marginTop: '0px',
                      }}
                    >
                      {filterLabel} Tags
                    </button>
                  </div>
                  <div className="col-12 mx-0 p-1" style={{ float: 'left' }}>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card">
                        <div className="accordion-body">
                          <div
                            className="col-2 mx-0 p-3"
                            style={{ float: 'left' }}
                          >
                            Select Category
                            <select
                              value={selectedCategory}
                              onChange={(e) =>
                                setSelectedCategory(e.target.value)
                              }
                              required
                              className="form-control"
                            >
                              <option value="">Select</option>
                              {Array.isArray(categories) &&
                                categories.map((category) => (
                                  <option
                                    key={category.qc_uid}
                                    value={category._id}
                                  >
                                    {category.name}
                                  </option>
                                ))}
                            </select>
                          </div>

                          <div
                            className="col-2 mx-0 p-3"
                            style={{ float: 'left' }}
                          >
                            Start date
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Start date"
                              onChange={(e) => {
                                setStartDate(e.target.value)
                              }}
                            />
                          </div>
                          <div
                            className="col-2 mx-0 p-3"
                            style={{ float: 'left' }}
                          >
                            End date
                            <input
                              type="date"
                              className="form-control"
                              placeholder="End date"
                              onChange={(e) => {
                                setEndDate(e.target.value)
                              }}
                            />
                          </div>
                          <div
                            className="col-2 mx-0 p-3"
                            style={{ float: 'left' }}
                          >
                            <input
                              type="button"
                              className="readline-btn-down mx-1 mt-4"
                              value="Filter"
                              onClick={(e) => {
                                handleFilterFiles()
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <table className="documents-table task_tabl">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Document Name</th>
                        <th>Category</th>
                        <th>Submit Date</th>
                        <th>Risk Score (%)</th>
                        <th>Owned By</th>
                        <th className="wid_40pxls text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    
                      {loading && (
                        <tr>
                          <td colSpan={7}>
                            <BeatLoader
                              color={'#000'}
                              loading={loading}
                              cssOverride={override}
                              size={10}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </td>
                        </tr>
                      )}
                      {!loading && files.length === 0 && (
                        <tr>
                          <td colSpan={7}>No files found.</td>
                        </tr>
                      )}
                      {!loading &&
                        files.map((file) => {
                          const categoryColor =
                            categoryColors[file.qc_id?._id] || '#ccc'
                          return (
                            <tr key={file._id}>
                              <td>
                                <span
                                  className="category-dot"
                                  style={{
                                    backgroundColor: categoryColor,
                                    display: 'inline-block',
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    marginRight: '8px',
                                  }}
                                ></span>
                              </td>
                              <td>{file.filename}</td>
                              <td>{file.qc_id?.name || 'N/A'}</td>
                              <td>
                                {new Date(
                                  file.added_date
                                ).toLocaleDateString()}
                              </td>
                              <td>
                                {file.ai_risk_score != null
                                  ? `${(file.ai_risk_score * 100).toFixed(2)}%`
                                  : 'N/A'}
                              </td>
                              <td>{file.user_id?.fname || 'Unknown'}</td>
                              <td>
                                <button
                                  className="btn_td_tabs drp_btn_tds wid_40pxls p-0 dropdown-toggle"
                                  type="button"
                                  id={`actionDropdown-${file._id}`}
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-more-vertical"
                                  >
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="12" cy="5" r="1"></circle>
                                    <circle cx="12" cy="19" r="1"></circle>
                                  </svg>
                                </button>
                                <ul
                                  className="dropdown-menu actions_ulss"
                                  aria-labelledby={`actionDropdown-${file._id}`}
                                >
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => fetchFileDetails(file._id)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-eye"
                                      >
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <circle cx="12" cy="12" r="4"></circle>
                                        <line
                                          x1="22"
                                          y1="12"
                                          x2="18"
                                          y2="12"
                                        ></line>
                                      </svg>
                                      Examine
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => handleViewFile(file._id)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-eye"
                                      >
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                      </svg>
                                      View
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => handleFixIt(file)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-download"
                                      >
                                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                        <polyline points="7 10 12 15 17 10"></polyline>
                                        <line
                                          x1="12"
                                          y1="15"
                                          x2="12"
                                          y2="3"
                                        ></line>
                                      </svg>
                                      Fix It
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => handleEdit(file._id)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-file-text"
                                      >
                                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                        <polyline points="14 2 14 8 20 8"></polyline>
                                        <line
                                          x1="16"
                                          y1="13"
                                          x2="8"
                                          y2="13"
                                        ></line>
                                        <line
                                          x1="16"
                                          y1="17"
                                          x2="8"
                                          y2="17"
                                        ></line>
                                        <polyline points="10 9 9 9 8 9"></polyline>
                                      </svg>
                                      Edit
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => {
                                        handleShowModal2()
                                        setis_password_required(
                                          file.link?.is_password_required
                                        )
                                        setlink_id(file.link?._id)
                                        setfile_id(file._id)
                                        setisURL(!!file.link?.url)
                                        seturl(file.link?.url)
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-share-2"
                                      >
                                        <circle cx="18" cy="5" r="3"></circle>
                                        <circle cx="6" cy="12" r="3"></circle>
                                        <circle cx="18" cy="19" r="3"></circle>
                                        <line
                                          x1="8.59"
                                          y1="13.51"
                                          x2="15.42"
                                          y2="17.49"
                                        ></line>
                                        <line
                                          x1="15.41"
                                          y1="6.51"
                                          x2="8.59"
                                          y2="10.49"
                                        ></line>
                                      </svg>
                                      Share
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => handleEditCategory(file)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-edit"
                                      >
                                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3l-9 9-4 1 1-4 9-9z"></path>
                                      </svg>
                                      Edit Category
                                    </button>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                  <br />
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            )}

            {activeTab === 'reports' && (
              <div className="reports-container">
                <Report />
              </div>
            )}

            {activeTab === 'Clauses' && <Clauses />}

            {/* CREATE-DOC TAB */}
            {activeTab === 'create-doc' && (
              <div className="px-1">
                <div className="mt-2">
                  <div className="create-doc-container">
                    <h2 className="create-doc-title">Generate Document</h2>

                    {/* Category & Buyer/Seller */}
                    <div className="mt-2">
                      <select
                        className="dropdown-select"
                        onChange={handleCategoryChangeForDocument}
                        value={selectedCategoryDocument || ''}
                      >
                        <option value="" disabled>
                          Select a Category
                        </option>
                        {categoriesForDocument.map((category) => (
                          <option key={category._id} value={category._id}>
                            {category.name}
                          </option>
                        ))}
                      </select>

                      <select
                        className="dropdown-select mt-2"
                        onChange={(e) =>
                          setBuyerOrSellerForDocument(e.target.value)
                        }
                        value={buyerOrSellerForDocument || ''}
                      >
                        <option value="" disabled>
                          Select Buyer or Seller
                        </option>
                        <option value="buyer">Buyer</option>
                        <option value="seller">Seller</option>
                      </select>

                      {/* 
                        ORIGINAL Single reference doc select (commented out as requested):
                        
                        <select
                          className="dropdown-select me-3"
                          onChange={(e) => setSelectedReferenceDoc(e.target.value)}
                          value={selectedReferenceDoc}
                        >
                          <option value="">
                            Select a reference document
                          </option>
                          {referenceDocs.map((doc) => (
                            <option key={doc._id} value={doc._id}>
                              {doc.filename}
                            </option>
                          ))}
                        </select>
                      */}

                      {/* NEW: Multi-select with React Select */}
                      <div className="mt-2" style={{ width: '400px' }}>
                        <Select
                          isMulti
                          value={multiSelectedRefs}         // array of { value, label }
                          onChange={handleMultiSelectChange} // updates local state
                          options={multiRefOptions}          // array of { value, label }
                          placeholder="Select multiple reference docs"
                        />
                      </div>

                      {/* Button to upload new reference */}
                      <div className="mt-2 d-flex align-items-center">
                        <button
                          className="toolbar-btn"
                          onClick={handleChooseReferenceFile}
                        >
                          Upload Reference
                        </button>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={onFileInputChange}
                        />
                      </div>
                    </div>

                    {/* Text Editor */}
                    <div
                      contentEditable="true"
                      className="text-editor"
                      placeholder="Enter requirements about the document"
                      ref={editorRef}
                      style={{ marginTop: '20px' }}
                    ></div>

                    <button
                      className="toolbar-btn"
                      style={{ marginTop: '20px' }}
                      onClick={handleCreateDocument}
                    >
                      Generate Document
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* REDLINE TAB (NOW BACKEND-DRIVEN) */}
            {activeTab === 'redline' && (
              <div className="px-1">
                <div className="mt-2">
                  <div className="create-doc-container">
                    <h2 className="create-doc-title">Redline Document</h2>

                    {/* Category & Buyer/Seller */}
                    <div className="mt-2">
                      <select
                        className="dropdown-select"
                        onChange={handleCategoryChangeForRedline}
                        value={redlineSelectedCategory || ''}
                      >
                        <option value="" disabled>
                          Select a Category
                        </option>
                        {categoriesForDocument.map((category) => (
                          <option key={category._id} value={category._id}>
                            {category.name}
                          </option>
                        ))}
                      </select>

                      <select
                        className="dropdown-select mt-2"
                        onChange={(e) => setRedlineBuyerOrSeller(e.target.value)}
                        value={redlineBuyerOrSeller || ''}
                      >
                        <option value="" disabled>
                          Select Buyer or Seller
                        </option>
                        <option value="buyer">Buyer</option>
                        <option value="seller">Seller</option>
                      </select>

                      {/* Reference Document Select + Upload */}
                      <div className="mt-2 d-flex align-items-center">
                        <select
                          className="dropdown-select me-3"
                          onChange={(e) =>
                            setRedlineSelectedReferenceDoc(e.target.value)
                          }
                          value={redlineSelectedReferenceDoc}
                        >
                          <option value="">Select a reference document</option>
                          {referenceDocs.map((doc) => (
                            <option key={doc._id} value={doc._id}>
                              {doc.filename}
                            </option>
                          ))}
                        </select>

                        {/* Button to upload new reference */}
                        <button
                          className="toolbar-btn"
                          onClick={handleChooseReferenceFile}
                        >
                          Upload Reference
                        </button>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={onFileInputChange}
                        />
                      </div>
                    </div>

                    {/* SECOND SELECT for the main doc to be redlined */}
                    <div className="mt-2 mb-2">
                      <select
                        className="dropdown-select me-3"
                        onChange={(e) =>
                          setRedlineDocumentForComparison(e.target.value)
                        }
                        value={redlineDocumentForComparison}
                      >
                        <option value="">Select a redline document</option>
                        {referenceDocs.map((doc) =>
                          !doc.isReferenceDoc && (
                            <option key={doc._id} value={doc._id}>
                              {doc.filename}
                            </option>
                          )
                        )}
                      </select>
                    </div>

                    {/* Editor text is optional, not used by fixIt but left in place */}
                    <div
                      contentEditable="true"
                      className="text-editor"
                      placeholder="Optional notes about the redline"
                      ref={redlineEditorRef}
                    ></div>

                    <button
                      className="toolbar-btn"
                      style={{ marginTop: '20px' }}
                      onClick={handleRedlineDocument}
                    >
                      Generate Redline
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Share Modal */}
      {showModal2 && (
        <>
          <div
            className="modal fade show"
            id="documentModal"
            aria-labelledby="documentModalLabel"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content border-none">
                <div className="modal-body p-0">
                  <div className="container">
                    <div className="row">
                      <div className="col-8">
                        <h2 className="modal-headings pt-1 m-0">
                          Send Document
                        </h2>
                      </div>
                      <div className="col-4 text-right">
                        <button
                          className="btn_close_x p-0 m-0"
                          onClick={() => {
                            setShowModal2(false)
                          }}
                          style={{ marginTop: '0px' }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#6b7280"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-3 d-flex align-items-center">
                        <input
                          type="checkbox"
                          checked={is_password_required === 'yes'}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setis_password_required('yes')
                            } else {
                              setis_password_required('no')
                            }
                          }}
                        ></input>
                        <p className="my-0 mx-2 paspro">Password Protected</p>
                      </div>
                      {is_password_required === 'yes' && (
                        <div className="col-12 mt-2">
                          <label className="paslbl">Enter Password</label>
                          <input
                            className="nptpass"
                            type="password"
                            onChange={(e) => setpassword(e.target.value)}
                            placeholder="Please enter password"
                          ></input>
                        </div>
                      )}
                      {url && (
                        <div className="col-12 mt-2">
                          <label className="paslbl">Link</label>
                          <div className="linkgen">{url}</div>
                        </div>
                      )}

                      {url && (
                        <div className="col-12 mt-2 text-right">
                          <button
                            onClick={() => {
                              navigator.clipboard
                                .writeText(url)
                                .then(() => {
                                  toast.success('Copied')
                                })
                                .catch((err) =>
                                  console.error('Failed to copy text: ', err)
                                )
                            }}
                            className="copylinks m-0"
                          >
                            Copy Link
                          </button>
                        </div>
                      )}

                      <div className="col-12 mt-2">
                        <button
                          onClick={createLink}
                          className="go-back ms-auto me-0"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => {
              setShowModal2(false)
            }}
          ></div>
        </>
      )}

      {/* Examine Modal */}
      {showExamineModal && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        >
          <div className="modal-dialog modal-xl mdl-neww">
            <div className="modal-content max-min-wid">
              <div className="row">
                <div className="col-8 pt-1">
                  <h2 className="cards_headings upper_case_textt m-0">
                    {examineFile ? examineFile.filename : ''}
                  </h2>
                </div>
                <div className="col-4 text-right">
                  <button
                    className="modal_close_btn"
                    onClick={() => {
                      setShowExamineModal(false)
                      setExamineDocumentUrl('')
                      setAiExplanations([])
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-x"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </button>
                </div>
              </div>

              <div className="modal-body p-0">
                {/* AI Provider Toggle */}
                <div
                  style={{
                    marginBottom: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div className="mt-3">
                    <span className="cards_headings ">Select AI Provider:</span>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      className="btn_slcts"
                      style={{
                        backgroundColor: useClaude ? '#121212' : 'transparent',
                        color: useClaude ? '#fff' : '#969696',
                        border: '0 solid #ffffff',
                      }}
                      onClick={() => setUseClaude(true)}
                    >
                      Claude
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn_slcts"
                      style={{
                        backgroundColor: !useClaude ? '#121212' : 'transparent',
                        color: !useClaude ? '#fff' : '#969696',
                        border: '0 solid #007bff',
                      }}
                      onClick={() => setUseClaude(false)}
                    >
                      OpenAI
                    </button>
                  </div>
                </div>

                {/* Analyze Text Button */}
                <div>
                  <button
                    className="btn_anylyzi"
                    onClick={handleAnalyzeText}
                    disabled={!selectedText}
                  >
                    Analyze Text
                  </button>
                </div>

                {/* Document Viewer */}
                <div className="row mt-4">
                  {examineDocumentUrl && (
                    <div className="col fixz-width">
                      <div
                        className="fix_hei_pre"
                        onMouseUp={handleTextSelection}
                      >
                        {/* For PDF */}
                        {examineDocumentUrl.includes('pdf') && (
                          <Document
                            file={examineDocumentUrl}
                            onLoadSuccess={onExamineDocumentLoadSuccess}
                          >
                            {Array.from(new Array(examineNumPages), (el, index) => (
                              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                          </Document>
                        )}
                        {/* For DOCX */}
                        {examineDocumentUrl.includes('docx') && (
                          <div
                            ref={examineContainerRef}
                            style={{
                              overflow: 'auto',
                              whiteSpace: 'pre-wrap',
                              width: '100%',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}

                  {/* AI Explains Section */}
                  <div className="col">
                    <div className="fix_hei_pre">
                      <h5 className="cards_headings">AI Explains</h5>
                      {aiExplanations.length === 0 ? (
                        <p>No explanations yet.</p>
                      ) : (
                        aiExplanations.map((item, index) => (
                          <div key={index} style={{ marginBottom: '2rem' }}>
                            <strong className="ai_explnti">
                              {index + 1}.{' '}
                              {item.loading ? 'Loading...' : 'Selected Text:'}
                            </strong>
                            {!item.loading && (
                              <>
                                <p className="selct_text">
                                  {item.selectedText}
                                </p>
                                <strong className="ai_explnti">
                                  AI Explanation:
                                </strong>
                                <p className="selct_text">{item.aiResponse}</p>
                              </>
                            )}
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showEditCategoryModal && (
        <div
          className="modal show d-block"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" ref={updateCategoryRef}>
              <div className="modal-header">
                <h5 className="modal-title">Edit Category</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowEditCategoryModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  <strong>Document Name:</strong> {editFile?.filename}
                </p>
                <label htmlFor="category-select">Select Category:</label>
                <select
                  id="category-select"
                  className="form-control"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option value="">Select</option>
                  {categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowEditCategoryModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="readline-btn-down text-center"
                  onClick={handleCategoryChange}
                  disabled={!newCategory}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Documents