import axios from 'axios'
import React from 'react'
import { useContext, useEffect, useRef, useState } from 'react'
import { BASE_URL } from '../config'
import AuthContext from '../AuthContext'
import dayjs from 'dayjs'

const Repository = () => {
    const [showModal, setShowModal] = useState(false)
    const { userToken } = useContext(AuthContext)
    const handleShowModal = () => setShowModal(true)
    const [currentPage, setcurrentPage] = useState(1)
    const [fileList, setfileList] = useState([])
    const [selectedFile, setSelectedFile] = useState(null)
    const [isUploading, setisUploading] = useState(false)
    const handleCloseModal = () => {
      setShowModal(false)
    }
    useEffect(() => {
      getFileList()
    }, [currentPage])
    
    const getFileList = () => {
      axios.post(`${BASE_URL}/user/repository/file/list`, { 
         "page":currentPage,
    "size":"10"
       }, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then(resp => {
          console.log(resp.data)
          if(resp.data.status === 1){
            setfileList(resp.data.data)
          }
        })
        .catch(err => {
          console.error('Error exchanging code with backend:', err)
        })
    }
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0])
    }
    const handleUpload = () => {
      setisUploading(true)
      const formData = new FormData()
      formData.append('file', selectedFile)
      axios.post(`${BASE_URL}/user/repository/file/save`, formData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(resp => {
          setisUploading(false)
          console.log(resp.data)
          setShowModal(false)
          getFileList()
          setSelectedFile(null)
        })
        .catch(err => {
          setisUploading(false)
          console.error('Error exchanging code with backend:', err)
        })
    }
    
  return (
   <>
    <div className='readline-container px-4 pt-4' >
        <div className='cards' >
            <h5 className='cards_headings pt-2'>Repository</h5>
            <div>
                <button className='upload-buttons2 d-block mleft-auto' onClick={()=>{setSelectedFile(null);handleShowModal()}} >Upload File</button>
                <table className='documents-table task_tabl mt-2' >
                    <thead>
                        <tr>
                            <th>File name</th>
                            <th>Added date</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr className='bordertdnone' >
                          <td colSpan={10} ></td>
                        </tr>
                      {fileList.length==0 && <tr><td colSpan={2}>No files found</td></tr>}
                        {fileList.map((file) => <tr key={file._id}>
                            <td>{file.filename_original}</td>
                            <td>{dayjs(file.added_date).format('DD-MM-YYYY')}</td>
                        </tr>)}
                      
                    </tbody>
                </table>
            </div>
        </div>
    </div>


    {showModal && (
        <>
          <div
            className="modal fade show"
            id="documentModal"
            aria-labelledby="documentModalLabel"
            role="dialog"
            style={{ zIndex: 1 }}
          >
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <div className="modal-content border-none p-3">
                <div className="modal-body p-0">
                  <div className="container px-3 py-2">
                    <div className="row">
                      <div className="col-8">
                        <h2 className="modal-headings pt-1 m-0">
                          Upload File
                        </h2>
                      </div>
                      <div className="col-4 text-right">
                        <button
                          className="btn_close_x p-0 m-0"
                          onClick={handleCloseModal}
                          style={{ marginTop: '0px' }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#6b7280"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <section className="file_upload_section my-3">
                          <div className="upload-drag">
                            <input type='file' id="selectfiless" onChange={handleFileChange} className='opci-non'></input>
                            <label htmlFor="selectfiless">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="44"
                                height="44"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#6b7280"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-upload"
                              >
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                <polyline points="17 8 12 3 7 8"></polyline>
                                <line x1="12" y1="3" x2="12" y2="15"></line>
                              </svg>
                            </label>
                           
                            {!selectedFile &&<span className="upload-limit font-12 mt-2">Click here to select file</span>}
                            {selectedFile &&<span className="upload-limit font-12 mt-2">{selectedFile?.name}</span>}
                          </div>
                        
                        </section>
                      </div>
                      <div className='col-12'>
                      <button disabled={selectedFile==null} onClick={handleUpload} className='upload-buttons2 d-block m-auto' >{isUploading ? 'Uploading...' : 'Submit'}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* BACKDROP */}
          <div
            className="modal-backdrop fade show"
            onClick={handleCloseModal}
          ></div>
        </>
      )}
    




   </>
  )
}

export default Repository