import axios from 'axios'
import React from 'react'
import { useContext, useEffect, useState } from 'react'
import { BASE_URL } from '../config'
import AuthContext from '../AuthContext'
import Select from "react-select";

const WorkFlow = () => {
  const { userToken } = useContext(AuthContext)

  const [selectedOption, setSelectedOption] = useState([])
  const [options, setOptions] = useState([])

  // NEW: this will store multiple questions in one text area (each line is a question)
  const [questionsText, setQuestionsText] = useState('')

  const [fileList, setFileList] = useState([])
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDownload, setIsLoadingDownload] = useState(false)

  const [savedQuestionList, setSavedQuestionList] = useState([])
  const [questionMessage, setQuestionMessage] = useState('Loading...')
  


  useEffect(() => {
    getFileList();
    getQuestionList();
  }, [])

  const getFileList = () => {
    axios.post(`${BASE_URL}/user/repository/file/list`,
      { page: '1', size: '100' },
      { headers: { Authorization: `Bearer ${userToken}` } }
    )
      .then(resp => {
        if (resp.data.status === 1) {
          let newOptions = resp.data.data.map(file => ({
            value: file._id,
            label: file.filename_original
          }))
          setOptions(newOptions)
        }
      })
      .catch(err => {
        console.error('Error fetching file list:', err)
      })
  }

  const getQuestionList = () => {
    axios.post(`${BASE_URL}/user/workflow/question/list`,
      { page: '1', size: '100' },
      { headers: { Authorization: `Bearer ${userToken}` } }
    )
      .then(resp => {
        if (resp.data.status === 1) {
          var questions = resp.data.data;
          var questionArray = [];
          if (questions && questions.length) {
            // loop questions
            questions.map((question, index) => {

              // loop answers
              if (question.answer && (question.answer).length) {
                (question.answer).map((ans, index) => {
                  questionArray.push({
                    question: ans.question,
                    answer: ans.answer,
                    id: question._id,
                  });
                })
              }
              
            })
          }
          setSavedQuestionList(questionArray)
          if (questionArray.length) {
            setQuestionMessage('');
          }
          else{
            setQuestionMessage('No questions found');
          }
        }
        else{
          setQuestionMessage('No questions found');
        }
      })
      .catch(err => {
        console.error('Error fetching file list:', err)
      })
  }

  const handleChange = (sel) => {
    setSelectedOption(sel)
  }

  /**
   * When user clicks "Generate Document":
   *  1) Ensure at least one file is selected
   *  2) Ensure the user has typed in at least one question
   *  3) Convert the multi-line text into an array of questions
   *  4) Call the new backend endpoint that uses Claude
   *  5) On success, auto-download a text file with Q&A
   */
  const handleGenerate = (action) => {
    setIsSubmitted(true)

    // Validate
    if (selectedOption.length === 0) {
      return
    }
    if (!questionsText.trim()) {
      return
    }

    const fileIds = selectedOption.map(opt => opt.value).join(',')

    // Convert multiline questions => array
    // e.g., each line is one question
    const questionLines = questionsText
      .split('\n')
      .map(line => line.trim())
      .filter(line => line.length > 0)

    if (questionLines.length === 0) {
      return
    }

    if (action === 'download_ans') { setIsLoadingDownload(true) }
    else { setIsLoading(true) }

    axios.post(
      `${BASE_URL}/user/generateWorkflow`,
      {
        file_ids: fileIds,
        questions: questionLines, // pass as array
        action
      },
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(resp => {
        setIsLoading(false)
        setIsLoadingDownload(false)

        if (resp.data.status === 1) {
          // The server can return something like:
          // data: [ { question: "...", answer: "..." }, ... ]
          const answers = resp.data.data

          // 1) Build a text string containing all Q&A
          if (action === 'download_ans') {
            let textContent = ''
            answers.forEach((qa, idx) => {
              textContent += `Question ${idx + 1}: ${qa.question}\n`
              textContent += `Answer ${idx + 1}:\n${qa.answer}\n\n`
            })

            // 2) Create a Blob & auto-download it as a .txt file
            const blob = new Blob([textContent], { type: 'text/plain' })
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = 'answers.txt'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            URL.revokeObjectURL(url)
          }
          else {
            // Optionally clear form
            setSelectedOption([])
            setQuestionsText('')
            setFileList(resp.data.data)
            setIsSubmitted(false);
            getQuestionList();
          }
        }
      })
      .catch(err => {
        setIsLoading(false)
        console.error('Error generating workflow:', err)
      })
  }

  return (
    <div className="readline-container px-4 pt-4">
      <div className="cards">
        <h5 className="cards_headings pt-2">Work Flow</h5>
        <div className="row">
          <div className="col-6">
            {/* Files multiselect */}
            <Select
              isMulti
              value={selectedOption}
              onChange={handleChange}
              options={options}
            />
            {isSubmitted && selectedOption.length === 0 && (
              <span className="text-danger mt-2">Please select at least one file</span>
            )}

            {/* Multi-line text area for multiple questions */}
            <textarea
              className="text-workflow w-100 mt-3"
              placeholder="Enter each question on a new line"
              rows={6}
              value={questionsText}
              onChange={(e) => setQuestionsText(e.target.value)}
            />
            {isSubmitted && !questionsText.trim() && (
              <div className="text-danger mt-2 col-12">Please enter at least one question</div>
            )}

            {/* Generate Document button */}
            <button
              onClick={() => { handleGenerate('generate_ans') }}
              className="upload-buttons2 mt-3"
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Generate Answer'}
            </button>
            <button
              onClick={() => { handleGenerate('download_ans') }}
              className="upload-buttons2 mt-3"
              disabled={isLoadingDownload}
            >
              {isLoadingDownload ? 'Downloading...' : 'Download Answer'}
            </button>
          </div>

          {fileList.length > 0 && (
            <div className="col-6">
              <h5 className="cards_headings pt-2 pb-2">Response</h5>
              <div className="cards">
                {fileList.map((item, index) => (
                  <div className="row" key={index}>
                    <div className="col-12">
                      <b>{item.filename}</b>
                    </div>
                    <div className="col-12 pt-2 pb-3">
                      {item.answer}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="cards">
        <h5 className="cards_headings pt-2">Questions</h5>
        <div className="row">

        {savedQuestionList.length === 0 && (
            <p>{questionMessage}</p>
          )}
          <div className="accordion w-100" id="questionsAccordion">
            {savedQuestionList && savedQuestionList.map((item, index) => (
              <div className="accordion-item mt-2 border-top" key={index}>
                <h2 className="accordion-header" style={{ marginTop: "unset" }}>
                  <button
                    className="accordion-button collapsed workflow-buttons"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded="false"
                    aria-controls={`collapse${index}`}
                    style={{ width: "99%" }}
                  >
                    <b>{item.question}</b>
                  </button>
                </h2>
                <div
                  id={`collapse${index}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#questionsAccordion"
                >
                  <div className="accordion-body">
                    {item.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkFlow