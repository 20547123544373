import axios from 'axios'
import { BASE_URL , DOCUSIGN_OAUTH_BASE_URL, DOCUSIGN_CLIENT_ID, DOCUSIGN_REDIRECT_URI} from '../config'
import { useContext, useEffect, useRef, useState } from 'react'
import AuthContext from '../AuthContext'
import { ThemeContext } from './../ThemeContext'
import QAFetchContext from '../QaFetchContext'
import useDebounce from './useDebounce'
import { useLocation, useNavigate } from 'react-router-dom'
import SemiCircleChart from './SemiCircleChart'
import { BeatLoader } from 'react-spinners'
import Drive from '../img/google-drive.png'
import Share from '../img/office.png'
import DocuSignLogo from '../img/docusign.webp' // Update with correct path
import { useGoogleLogin } from '@react-oauth/google'

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#000',
  justifyContent: 'center',
}



const Header = () => {
  const location = useLocation()
  const { userToken } = useContext(AuthContext)
  const {
    isFetchingQA,
    setIsFetchingQA,
    proccessingFiles,
    addFileToProcessing,
    removeFileFromProcessing,
  } = useContext(QAFetchContext)
  const { theme, toggleTheme } = useContext(ThemeContext)
  const navigate = useNavigate()

  // ================== STATE FOR DOCUSIGN LISTING/DOWNLOADING ==================
  const [showDocuSignModal, setShowDocuSignModal] = useState(false)
  const [docuSignEnvelopes, setDocuSignEnvelopes] = useState([])
  const [selectedEnvelope, setSelectedEnvelope] = useState(null)
  const [docuSignDocs, setDocuSignDocs] = useState([])
  const [docuSignLoading, setDocuSignLoading] = useState(false)

  // ================== EXISTING STATES BELOW ==================
  const [showModal, setShowModal] = useState(false)
  const [isSearchFor, setisSearchFor] = useState(false)
  const [showComingSoon, setshowComingSoon] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [showSuggestion, setshowSuggestion] = useState(false)
  const [showPromptModal, setshowPromptModal] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)

  const [area_livability_chart, setarea_livability_chart] = useState(false)
  const [top_5_occupation, settop_5_occupation] = useState(false)
  const [area_livability_chartList, setarea_livability_chartList] = useState([])
  const [top_5_occupationList, settop_5_occupationList] = useState([])
  const [CompletedFiles, setCompletedFiles] = useState([])
  const [categories, setCategories] = useState([])
  const [notifications, setNotifications] = useState([])
  const [notificationCount, setNotificationCount] = useState(0)

  // File upload state
  const [selectedFile, setSelectedFile] = useState([])
  const [buyerSeller, setBuyerSeller] = useState('')
  const [discussionPoint, setDiscussionPoint] = useState('')
  const [isFileUploaded, setisFileUploaded] = useState(false)

  // Google Drive references
  const [fileId, setFileId] = useState(null)
  const [fileName, setFileName] = useState(null)
  var [accessToken, setAccessToken] = useState(null)
  var [uploadedFileName, setUploadedFileName] = useState(null)
  var [uploadedOriginalFileName, setUploadedOriginalFileName] = useState(null)

  // Searching
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 500)
  const [showList, setshowList] = useState(false)
  const suggestionsRef = useRef(null)

  // File input ref
  const fileInputRef = useRef(null)

  // ================== GOOGLE DRIVE OAUTH ==================
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setAccessToken(tokenResponse.access_token)
      localStorage.setItem('gapi_accessToken', tokenResponse.access_token)
      const pickerApi = await loadPicker()
      createPicker(tokenResponse.access_token)
    },
    onError: (errorResponse) =>
      console.log('Error during Google login:', errorResponse),
    scope: 'https://www.googleapis.com/auth/drive.readonly',
  })

  // Load Google Picker API
  const loadPicker = () => {
    return new Promise((resolve) => {
      window.gapi.load('picker', resolve)
    })
  }

  // Create the Google Picker widget
  const createPicker = (accessToken) => {
    const view = new window.google.picker.DocsView(window.google.picker.ViewId.DOCS)
      .setIncludeFolders(true)
      .setMimeTypes(
        'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain'
      )
      .setSelectFolderEnabled(false)

    const picker = new window.google.picker.PickerBuilder()
      .addView(view)
      .setOAuthToken(accessToken)
      .setDeveloperKey('AIzaSyBCCH39RtJ1BTPx1prgbXnfG3NBT_k-uNI') // Replace with your actual API key
      .setCallback(pickerCallback)
      .build()
    picker.setVisible(true)
  }

  // Picker callback
  const pickerCallback = async (data) => {
    if (!accessToken) {
      accessToken = localStorage.getItem('gapi_accessToken')
    }
    if (data.action === window.google.picker.Action.PICKED) {
      const file = data.docs[0]
      setFileId(file.id)
      setFileName(file.name)

      if (!accessToken) {
        console.error('Access token is missing')
        return
      }
      try {
        // Download the file from Google Drive
        const downloadUrl = `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`
        const response = await axios({
          url: downloadUrl,
          method: 'GET',
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: 'blob',
        })

        // Send the file to the Node.js server
        const formData = new FormData()
        formData.append('file', response.data, file.name)

        const uploadResponse = await axios.post(
          `${BASE_URL}/global/upload-drive-file`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        if (uploadResponse.data.status === 1) {
          const { filename, original_filename } = uploadResponse.data
          setUploadedOriginalFileName(original_filename)
          setUploadedFileName([{ filename, original_filename }])
          setSelectedFile([response.data])
        } else {
          setError(
            uploadResponse.data.message ||
              'An error occurred during the file upload.'
          )
        }
      } catch (error) {
        console.error('Error downloading the file:', error)
      }
    }
  }

  // ================== DOCUSIGN OAUTH CODE DETECTION ==================
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get('code')
    if (code) {
      // Exchange code for DocuSign data
      axios.post(`${BASE_URL}/docusign/exchange-code`, { code })
        .then(resp => {
          console.log('DocuSign data from backend:', resp.data)
        })
        .catch(err => {
          console.error('Error exchanging code with backend:', err)
        })
    }
  }, [])

  // ================== NOTIFICATIONS ==================
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/notifications`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        if (response.data.status === 1) {
          setNotifications(response.data.notifications)
          setNotificationCount(response.data.notifications.length)
        } else {
          console.error('Error fetching notifications:', response.data.message)
        }
      } catch (error) {
        console.error('Error fetching notifications:', error)
      }
    }
    fetchNotifications()
    const interval = setInterval(fetchNotifications, 60000)
    return () => clearInterval(interval)
  }, [userToken])

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications)
  }

  const markAsRead = async (notificationId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/notifications/${notificationId}/mark-as-read`,
        {},
        { headers: { Authorization: `Bearer ${userToken}` } }
      )
      if (response.data.status === 1) {
        setNotifications((prev) =>
          prev.filter((notif) => notif._id !== notificationId)
        )
        setNotificationCount((prev) => prev - 1)
      } else {
        console.error('Error marking notification as read:', response.data.message)
      }
    } catch (error) {
      console.error('Error marking notification as read:', error)
    }
  }

  const deleteNotification = async (notificationId) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/notifications/${notificationId}`,
        { headers: { Authorization: `Bearer ${userToken}` } }
      )
      if (response.data.status === 1) {
        setNotifications((prev) =>
          prev.filter((notif) => notif._id !== notificationId)
        )
        setNotificationCount((prev) => prev - 1)
      } else {
        console.error('Error deleting notification:', response.data.message)
      }
    } catch (error) {
      console.error('Error deleting notification:', error)
    }
  }

  // ================== SEARCH LOGIC ==================
  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    setCompletedFiles([])
    if (debouncedQuery) {
      settop_5_occupation(false)
      setarea_livability_chart(false)
      fetchCompletedFiles()
    }
  }, [debouncedQuery])

  const fetchCategories = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/category/list`,
        { page: '0', size: '10' },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data.status === 1) {
        setCategories(response.data.categories)
      } else {
        setError(response.data?.data?.message || 'Failed to fetch categories')
      }
    } catch (error) {
      setError('Failed to fetch categories')
    }
  }

  const fetchCompletedFiles = async () => {
    if (debouncedQuery !== 'Find ') {
      setLoading(true)
      try {
        const response = await axios.post(
          `${BASE_URL}/user/file/list`,
          { page: '1', size: '10', keyword: debouncedQuery },
          { headers: { Authorization: `Bearer ${userToken}` } }
        )
        if (response.data.status === 1) {
          const { popup_name, data } = response.data
          if (popup_name === 'area_livability_chart') {
            setarea_livability_chart(true)
            settop_5_occupation(false)
            setarea_livability_chartList(data)
            settop_5_occupationList([])
          } else if (popup_name === 'top_5_occupation') {
            setarea_livability_chartList([])
            settop_5_occupationList(data)
            setarea_livability_chart(false)
            settop_5_occupation(true)
          } else {
            setarea_livability_chartList([])
            settop_5_occupationList([])
            settop_5_occupation(false)
            setarea_livability_chart(false)
            const demosearch = query
            localStorage.setItem(
              'currentSearch',
              demosearch.replace('search for', '')
            )
            if (showList && data.length > 0) {
              localStorage.setItem('isSearchUser', data[0]._id)
              setCompletedFiles(data)
            } else if (data.length > 0) {
              localStorage.setItem('isSearchUser', data[0]._id)
              navigate('/documents')
            }
          }
        }
      } catch (error) {
        console.error('Error fetching completed files:', error)
      } finally {
        setLoading(false)
      }
    }
  }

  // For toggling suggestions
  const handleClickOutside = (event) => {
    if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
      setshowSuggestion(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleKeyDown = (event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault()
      setshowSuggestion((prev) => !prev)
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (area_livability_chart || top_5_occupation) {
        setshowPromptModal(true)
      } else {
        const isSearchUser = localStorage.getItem('isSearchUser')
        if (isSearchUser) {
          const demosearch = query
          localStorage.setItem('currentSearch', demosearch.replace('search for', ''))
          navigate('/documents')
        }
      }
    }
  }

  useEffect(() => {
    setisSearchFor(query.toLowerCase().includes('search for'))
  }, [query])

  // ================== UPLOAD MODAL LOGIC ==================
  const handleShowModal = () => setShowModal(true)
  const handleCloseModal = () => {
    setSelectedFile([])
    setBuyerSeller('')
    setDiscussionPoint('')
    setShowModal(false)
    setshowPromptModal(false)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files)
    setSelectedFile(files)
  }

  const saveFiles = async (filename, original_filename, buyerSeller, discussionPoint) => {
    setisFileUploaded(false)
    try {
      const secondResponse = await axios.post(
        `${BASE_URL}/user/upload-and-search`,
        {
          filename,
          original_filename,
          user_type: buyerSeller,
          admin_id: '1234',
          discussion_point: discussionPoint,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )

      if (secondResponse.data.status === 1) {
        alert('File uploaded! Processing may take a while. Please wait...')
        handleCloseModal()

        // Hash the document
        const fileId = secondResponse.data.file_id
        handleHashDocument({ _id: fileId, filename: original_filename })
      } else {
        alert(
          secondResponse.data.message ||
            'An error occurred during the second upload step.'
        )
      }
    } catch (error) {
      alert('Error uploading files.')
    }
  }

  const handleUpload = async () => {
    if (selectedFile.length > 0 && buyerSeller) {
      try {
        let isUploaded = false
        let filename = []
        let original_filename = ''

        // If no file from Google Drive
        if (uploadedFileName == null) {
          selectedFile.forEach(async (file, index) => {
            const formData = new FormData()
            formData.append('file', file)
            const uploadResponse = await axios.post(
              `${BASE_URL}/global/upload-file`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${userToken}`,
                  'Content-Type': 'multipart/form-data',
                },
              }
            )
            if (uploadResponse.data.status) {
              filename.push({
                filename: uploadResponse.data.filename,
                original_filename: uploadResponse.data.original_filename,
              })
              original_filename = uploadResponse.data.original_filename
              isUploaded = true

              if (index === selectedFile.length - 1) {
                saveFiles(filename, original_filename, buyerSeller, discussionPoint)
              }
            }
          })
        } else {
          // If we had a file from Google Drive
          isUploaded = true
          filename = uploadedFileName
          original_filename = uploadedOriginalFileName

          // Immediately proceed to second step
          const secondResponse = await axios.post(
            `${BASE_URL}/user/upload-and-search`,
            {
              filename,
              original_filename,
              user_type: buyerSeller,
              admin_id: '1234',
              discussion_point: discussionPoint,
            },
            { headers: { Authorization: `Bearer ${userToken}` } }
          )

          if (secondResponse.data.status === 1) {
            alert('File uploaded and processed successfully!')
            handleCloseModal()

            const fileId = secondResponse.data.file_id
            const hashResponse = await handleHashDocument({ _id: fileId })
            console.log('Hash response:', hashResponse.data)

            window.location.reload()
          } else {
            alert(
              secondResponse.data.message ||
                'An error occurred during the second upload step.'
            )
          }
        }
        if (!isUploaded) {
          setError('An error occurred during the file upload.')
        }
      } catch (error) {
        setError('An error occurred during file upload')
      }
    } else {
      setLoading(false)
    }
  }

  const handleHashDocument = async (file) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/hash-document-and-check`,
        { file_id: file._id },
        { headers: { Authorization: `Bearer ${userToken}` } }
      )
      setLoading(false)
      if (response.data.status === 1) {
        // Document hashed
        for (let i = 0; i < response.data.file_hash.length; i++) {
          try {
            if (isFetchingQA) {
              alert('Data is already being fetched. Please wait.')
              return
            }
            setIsFetchingQA(true)
            addFileToProcessing(file)

            await axios.post(
              `${BASE_URL}/user/existing-file-qa`,
              { file_hash: response.data.file_hash[i].hash },
              { headers: { Authorization: `Bearer ${userToken}` } }
            )
          } catch (error) {
            setIsFetchingQA(false)
            removeFileFromProcessing(file)
            alert(
              error.response?.data?.message || 'Error fetching existing file QA'
            )
            console.error('Error fetching existing file QA:', error)
          }
        }
        setIsFetchingQA(false)
        removeFileFromProcessing(file)
      } else {
        console.error('Error hashing document:', response.data.message)
      }
      return response
    } catch (error) {
      console.error('Error hashing document:', error)
    }
  }

  // ================== DOCUSIGN POPUP (OAUTH) ==================
  const openDocuSignPopup = () => {
    const popupWidth = 600
    const popupHeight = 700
    const left = window.screenX + (window.outerWidth - popupWidth) / 2
    const top = window.screenY + (window.outerHeight - popupHeight) / 2
    const features = `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`

    const scope = 'signature'
    const docusignAuthUrl = `${DOCUSIGN_OAUTH_BASE_URL}?response_type=code&scope=${scope}&client_id=${DOCUSIGN_CLIENT_ID}&redirect_uri=${encodeURIComponent(
      DOCUSIGN_REDIRECT_URI
    )}`

    const popup = window.open(docusignAuthUrl, 'DocuSignAuth', features)

    const pollAuthCode = setInterval(() => {
      if (!popup || popup.closed) {
        clearInterval(pollAuthCode)
        return
      }
      try {
        const popupUrl = popup.location.href
        if (popupUrl.includes('?code=')) {
          const urlParams = new URLSearchParams(new URL(popupUrl).search)
          const authorizationCode = urlParams.get('code')
          clearInterval(pollAuthCode)
          popup.close()

          axios.post(`${BASE_URL}/docusign/exchange-code`, { code: authorizationCode })
            .then(resp => {
              console.log('DocuSign data from backend:', resp.data)
              alert('Successfully fetched DocuSign documents')
            })
            .catch(err => console.error('Error exchanging code:', err))
        }
      } catch (err) {
        // Cross-origin errors until it redirects
      }
    }, 1000)
  }

  // ================== OPEN DOCUSIGN "LISTING" MODAL ==================
  const handleOpenDocuSignModal = async () => {
    try {
      setDocuSignLoading(true)
      setShowDocuSignModal(true)
      // GET /docusign/list-envelopes from backend
      const resp = await axios.get(`${BASE_URL}/docusign/list-envelopes`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      if (resp.data.status === 1) {
        setDocuSignEnvelopes(resp.data.envelopes || [])
      }
    } catch (err) {
      console.error('Error fetching DocuSign envelopes:', err)
    } finally {
      setDocuSignLoading(false)
    }
  }

  const handleSelectEnvelope = async (envelopeId) => {
    setSelectedEnvelope(envelopeId)
    setDocuSignDocs([])
    try {
      setDocuSignLoading(true)
      // GET /docusign/list-docs/:envelopeId
      const resp = await axios.get(`${BASE_URL}/docusign/list-docs/${envelopeId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      })

      console.log('DocuSign docs in envelope:', resp.data);
      if (resp.data.status === 1) {
        setDocuSignDocs(resp.data.docs || [])
      }
    } catch (err) {
      console.error('Error fetching docs in envelope:', err)
    } finally {
      setDocuSignLoading(false)
    }
  }

  const handleSelectDoc = async (envelopeId, docId, docName) => {
    try {
      setDocuSignLoading(true)
      // GET /docusign/download-doc/:envelopeId/:docId
      const resp = await axios.get(`${BASE_URL}/docusign/download-doc/${envelopeId}/${docId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
        responseType: 'blob',
      })

      // Create a File from the blob
      const fileBlob = new Blob([resp.data], { type: 'application/pdf' })
      const file = new File([fileBlob], docName || `doc-${docId}.pdf`, { type: 'application/pdf' })

      // Replace or add to the selectedFile (here we’ll just replace for simplicity)
      setSelectedFile([file])
      alert(`Selected DocuSign file: ${docName}`);

      // Close the DocuSign listing modal, remain in the Upload modal
      setShowDocuSignModal(false)
    } catch (err) {
      console.error('Error downloading doc from envelope:', err)
    } finally {
      setDocuSignLoading(false)
    }
  }

  return (
    <>
      {/* ========== SEARCH UI, SUGGESTIONS, ETC. ========== */}
      <label className="position-relative search-header">
        {showSuggestion && (
          <>
            <div
              className="newsearchlist"
              ref={suggestionsRef}
              style={{ zIndex: '10' }}
            >
              <p className="px-3">Searching in files</p>
              <div className="wrapnewbtns px-3 d-flex">
                <button className="newsearbtn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-file"
                  >
                    <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                    <polyline points="13 2 13 9 20 9"></polyline>
                  </svg>
                  Documents
                </button>
                <button className="newsearbtn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-alert-circle"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="12"></line>
                    <line x1="12" y1="16" x2="12.01" y2="16"></line>
                  </svg>
                  Risk Score
                </button>
                <button className="newsearbtn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-layers"
                  >
                    <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                    <polyline points="2 17 12 22 22 17"></polyline>
                    <polyline points="2 12 12 17 22 12"></polyline>
                  </svg>
                  Task
                </button>
                <button className="newsearbtn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-user"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  User
                </button>
              </div>
            </div>
          </>
        )}
        {/* Search Icon */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-search"
        >
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
        <span className="sspn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-command"
          >
            <path d="M18 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3H6a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3V6a3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 3 3 0 0 0-3-3z"></path>
          </svg>
        </span>
        <span className="sspn1">K</span>
        <input
          type="text"
          onClick={() => setshowSuggestion(true)}
          value={query}
          onKeyUp={handleKeyPress}
          onChange={(e) => {
            setQuery(e.target.value)
            setshowList(true)
          }}
          placeholder="Search Documents"
        />
      </label>

      {/* ========== THEME, NOTIFS, UPLOAD BUTTONS ========== */}
      <div className="d-flex align-items-center">
        <button className="mode_theme_btn" onClick={toggleTheme}>
          <span className="lig">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-moon"
            >
              <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
            </svg>
          </span>
          <span className="dark">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-sun"
            >
              <circle cx="12" cy="12" r="5"></circle>
              <line x1="12" y1="1" x2="12" y2="3"></line>
              <line x1="12" y1="21" x2="12" y2="23"></line>
              <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
              <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
              <line x1="1" y1="12" x2="3" y2="12"></line>
              <line x1="21" y1="12" x2="23" y2="12"></line>
              <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
              <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
            </svg>
          </span>
        </button>

        {/* Notifications */}
        <div className="notification-bell mx-2">
          <div className="cirnotisvg" onClick={toggleNotifications}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-bell"
            >
              <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
              <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>
          </div>
          {notificationCount > 0 && (
            <span className="notification-count">{notificationCount}</span>
          )}
          {showNotifications && (
            <div className="notification-dropdown">
              <div className="not_card_head">
                <h1 className="notic_heading">Notifications</h1>
                <button
                  className="close_btn_noti"
                  onClick={toggleNotifications}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
              {notifications.map((notification) => (
                <div key={notification._id} className="notification-item">
                  <div className="not_msg_wraps">
                    <p className="not_msgss">{notification.message}</p>
                  </div>
                  <div className="notification-actions">
                    <button
                      onClick={() => markAsRead(notification._id)}
                      className="mark-as-read"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-check"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                      Mark as Read
                    </button>
                    <button
                      onClick={() => deleteNotification(notification._id)}
                      className="delete-notification"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trash"
                      >
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
              {notifications.length === 0 && (
                <div className="no-notifications">No new notifications</div>
              )}
            </div>
          )}
        </div>

        {/* Button to open normal upload modal */}
        <button className="btn_upld_head" type="button" onClick={handleShowModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ffffff"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-upload-cloud"
          >
            <polyline points="16 16 12 12 8 16"></polyline>
            <line x1="12" y1="12" x2="12" y2="21"></line>
            <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
            <polyline points="16 16 12 12 8 16"></polyline>
          </svg>
          &nbsp; Upload Documents
        </button>
      </div>

      {/* ================== MODAL: UPLOAD DOCUMENT ================== */}

      
      {showModal && (
        <>
          <div
            className="modal fade show"
            id="documentModal"
            aria-labelledby="documentModalLabel"
            role="dialog"
            style={{ zIndex: 1 }}
          >
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <div className="modal-content border-none p-3">
                <div className="modal-body p-0">
                  <div className="container px-3 py-2">
                    <div className="row">
                      <div className="col-8">
                        <h2 className="modal-headings pt-1 m-0">
                          Upload Document
                        </h2>
                      </div>
                      <div className="col-4 text-right">
                        <button
                          className="btn_close_x p-0 m-0"
                          onClick={handleCloseModal}
                          style={{ marginTop: '0px' }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#6b7280"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <section className="file_upload_section my-3">
                          <div className="upload-drag">
                            <label htmlFor="selectfiless">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="44"
                                height="44"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#6b7280"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-upload"
                              >
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                <polyline points="17 8 12 3 7 8"></polyline>
                                <line x1="12" y1="3" x2="12" y2="15"></line>
                              </svg>
                            </label>
                            <p className="upload-text font-13 w-700 mb-0 mt-3">
                              {selectedFile.length === 0
                                ? 'Drag and drop files here or'
                                : selectedFile
                                    .map((f) =>
                                      typeof f === 'string' ? f : f.name
                                    )
                                    .join(', ')}
                            </p>
                            <span className="upload-limit font-11">
                              Only *.xls, *.xlsx, *.csv, *.ods, *.pdf & 30 MB limit
                            </span>
                            <span className="upload-limit font-12 mt-2">Or</span>
                          </div>
                          <div>
                            <div className="row px-4 mt-2">
                              <div className="col-6 px-1">
                                <label className="lblselct" htmlFor="selectfiless">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-upload-cloud"
                                  >
                                    <polyline points="16 16 12 12 8 16"></polyline>
                                    <line x1="12" y1="12" x2="12" y2="21"></line>
                                    <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
                                    <polyline points="16 16 12 12 8 16"></polyline>
                                  </svg>
                                  Upload
                                  <input
                                    id="selectfiless"
                                    type="file"
                                    multiple
                                    className="file-input"
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                  />
                                </label>
                              </div>

                              <div className="col-6 px-1">
                                <button className="lblselct" onClick={() => login()}>
                                  <img src={Drive} alt="Google Drive" />
                                  Drive
                                </button>
                              </div>

                              <div className="col-6 px-1 mt-2">
                                <button
                                  className="lblselct"
                                  onClick={() => setshowComingSoon(true)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-folder"
                                  >
                                    <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                                  </svg>
                                  DMS Integrations
                                </button>
                              </div>

                              <div className="col-6 px-1 mt-2">
                                <button
                                  className="lblselct"
                                  onClick={() => setshowComingSoon(true)}
                                >
                                  <img src={Share} alt="SharePoint" />
                                  Sharepoint
                                </button>
                              </div>

                              {/* AUTHORIZE DOCUSIGN BUTTON */}
                              <div className="col-6 px-1 mt-2">
                                <button className="lblselct" onClick={openDocuSignPopup}>
                                  <img src={DocuSignLogo} alt="Docusign" />
                                  Authorize DocuSign
                                </button>
                              </div>

                              {/* SELECT FROM DOCUSIGN BUTTON */}
                              <div className="col-6 px-1 mt-2">
                                <button className="lblselct" onClick={handleOpenDocuSignModal}>
                                  <img src={DocuSignLogo} alt="Docusign" />
                                  Select from DocuSign
                                </button>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12">
                        <div className="form-group report_selects2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-chevron-down"
                          >
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>
                          <label>Buyer/Seller:</label>
                          <select
                            className="mt-0 mb-0"
                            value={buyerSeller}
                            onChange={(e) => setBuyerSeller(e.target.value)}
                            required
                          >
                            <option value="">Select</option>
                            <option value="buyer">Buyer</option>
                            <option value="seller">Seller</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group report_selects2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-chevron-down"
                          >
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <label>Discussion Point:</label>
                          <input
                            value={discussionPoint}
                            onChange={(e) => setDiscussionPoint(e.target.value)}
                            placeholder="Enter any points you want to discuss"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        {!loading && (
                          <button
                            className="btn_uplo_doc mx-auto my-0"
                            aria-label="Upload Documents"
                            onClick={() => {
                              setLoading(true)
                              handleUpload()
                            }}
                          >
                            Upload Documents
                          </button>
                        )}
                        {loading && (
                          <button
                            className="btn_uplo_doc mx-auto my-0"
                            aria-label="Upload Documents"
                          >
                            <BeatLoader
                              color={'#fff'}
                              loading={loading}
                              cssOverride={override}
                              size={10}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* BACKDROP */}
          <div
            className="modal-backdrop fade show"
            onClick={handleCloseModal}
          ></div>
        </>
      )}



      {/* ================== DOCUSIGN DOCUMENTS MODAL (NESTED) ================== */}
      {showDocuSignModal && (
        <>
          <div
            className="modal fade show"
            style={{ display: 'block', zIndex: 10000 }} // Higher z-index
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content border-none p-3">
                <div className="modal-header">
                  <h5 className="modal-title">Select a DocuSign Document</h5>
                  <button className="close" onClick={() => setShowDocuSignModal(false)}>
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  {docuSignLoading && (
                    <div style={{ textAlign: 'center' }}>
                      <BeatLoader color="#000" />
                    </div>
                  )}
                  {!docuSignLoading && (
                    <div>
                      {/* If no envelope selected yet, show the list of envelopes */}
                      {!selectedEnvelope && (
                        <>
                          <h5>Available Envelopes</h5>
                          <ul>
                            {docuSignEnvelopes.map(env => (
                              <li key={env.envelopeId} style={{ marginBottom: 10 }}>
                                <div>
                                  <strong>{env.emailSubject || 'No Subject'}</strong> 
                                  <br />
                                  EnvelopeId: {env.envelopeId}
                                </div>
                                <button
                                  onClick={() => handleSelectEnvelope(env.envelopeId)}
                                  className="btn btn-sm btn-primary mt-1"
                                >
                                  Choose Envelope
                                </button>
                              </li>
                            ))}
                          </ul>
                          {docuSignEnvelopes.length === 0 && (
                            <p>No envelopes found. Please authenticate. If authenticated, try sending a DocuSign envelope first.</p>
                          )}
                        </>
                      )}

                      {/* If an envelope is selected, show the docs in that envelope */}
                      {selectedEnvelope && docuSignDocs.length >= 0 && (
                        <>
                          <button
                            onClick={() => {
                              setSelectedEnvelope(null)
                              setDocuSignDocs([])
                            }}
                          >
                            ← Back to Envelopes
                          </button>
                          <h5>Documents in Envelope {selectedEnvelope}</h5>
                          <ul>
                            {docuSignDocs.map(doc => (
                              <li key={doc.documentId} style={{ marginBottom: 8 }}>
                                <strong>{doc.name}</strong> (ID: {doc.documentId})
                                <br />
                                <button
                                  className="btn btn-sm btn-success mt-1"
                                  onClick={() =>
                                    handleSelectDoc(selectedEnvelope, doc.documentId, doc.name)
                                  }
                                >
                                  Select This Doc
                                </button>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-secondary"
                    onClick={() => setShowDocuSignModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* A simple backdrop for the nested modal */}
          <div
            className="modal-backdrop fade show"
            style={{ zIndex: 1990 }} // Just below the modal
            onClick={() => setShowDocuSignModal(false)}
          />
        </>
      )}

      {/* ================== PROMPT MODAL (Area Livability / Top 5 Occupation) ================== */}
      {showPromptModal && (
        <>
          <div
            className="modal fade show"
            id="documentModal2"
            aria-labelledby="documentModalLabel"
            role="dialog"
          >
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <div className="modal-content border-none p-3 modaldocuments">
                <div className="modal-body p-0">
                  {area_livability_chart && (
                    <section className="py-2">
                      <div className="container pb-2">
                        <div className="row">
                          <div className="col-8 pt-2">
                            <h2 className="modheading d-flex align-items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-alert-circle"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                <line x1="12" y1="16" x2="12.01" y2="16"></line>
                              </svg>
                              &nbsp;&nbsp;Company Risk Score
                            </h2>
                          </div>
                          <div className="col-4 pb-3 text-right">
                            <button
                              className="btn_close_x p-0 m-0"
                              onClick={() => setshowPromptModal(false)}
                              style={{ marginTop: '0px' }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="17"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#6b7280"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-x"
                              >
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <SemiCircleChart />
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: '-30px' }}>
                          {area_livability_chartList.map((item, key) => (
                            <div
                              key={key}
                              className="col-6 mt-3"
                              style={{ cursor: 'pointer' }}
                            >
                              <div className="d-flex position-relative">
                                <span className="documentcounts">
                                  {item.score}
                                </span>
                                <div className="wrapdicon">
                                  <div className="docicons">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-file"
                                    >
                                      <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                                      <polyline points="13 2 13 9 20 9"></polyline>
                                    </svg>
                                  </div>
                                </div>
                                <div className="flex-2">
                                  <p className="paraMod">{item.category_name}</p>
                                  <div className="progressbar">
                                    <span style={{ width: item.percent }}></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </section>
                  )}
                  {top_5_occupation && (
                    <section>
                      <div className="container">
                        <div className="row">
                          <div className="col-8 pt-1">
                            <h2 className="modheading d-flex align-items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-briefcase"
                              >
                                <rect
                                  x="2"
                                  y="7"
                                  width="20"
                                  height="14"
                                  rx="2"
                                  ry="2"
                                ></rect>
                                <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                              </svg>
                              &nbsp;&nbsp; Top {top_5_occupationList.length} Highest Preview Risk Score
                            </h2>
                          </div>
                          <div className="col-4 text-right pb-2">
                            <button
                              className="btn_close_x p-0 m-0"
                              onClick={() => setshowPromptModal(false)}
                              style={{ marginTop: '0px' }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="17"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#6b7280"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-x"
                              >
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                              </svg>
                            </button>
                          </div>
                          <div className="col-12 px-0 pt-2">
                            <ul className="profoccupation">
                              {top_5_occupationList.map((item, key) => (
                                <li
                                  key={key}
                                  style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <span>{item.filename}</span>
                                  <span>({item.percent}%)</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* ================== COMING SOON MODAL ================== */}
      {showComingSoon && (
        <>
          <div className="modal  fade show">
            <div className="modal-dialog  modal-xl modal-dialog-centered">
              <div className="modal-content border-none p-3">
                <div className="modal-body p-0" style={{ width: '300px' }}>
                  <div className="container px-3 py-2">
                    <div className="row">
                      <div className="col-8"></div>
                      <div className="col-4 text-right">
                        <button
                          className="btn_close_x p-0 m-0"
                          onClick={() => setshowComingSoon(false)}
                          style={{ marginTop: '0px' }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#6b7280"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12" style={{ textAlign: 'center' }}>
                        <h2 style={{ margin: 0 }}>Coming soon</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Header
